/* EmployeeForm.css */

.employee-form {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.avatar-container {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  .avatar-wrapper {
    display: flex;
    position: relative;
    width: 120px;
    height: 120px;
    cursor: pointer;
  }
}

.avatar-image {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 4px solid #007bff;
}

.avatar-placeholder {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 2px dashed #007bff;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #007bff;
  font-size: 14px;
  cursor: pointer;
}

.remove-image {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 24px;
  height: 24px;
  background-color: #7d6c6d;
  color: #fff;
  border-radius: 50%;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    background-color: #4f4949;
  }
}
.form-wrapper {
  display: flex;
  align-items: center;
  position: relative;
}

.date-time-picker {
  width: 100%;
}

.gender-select {
  padding: 10px;
}

.address-textarea {
  resize: vertical;
}

.button-wrapper {
  display: flex;
  justify-content: flex-end;
  button {
    margin-right: 0;
  }
}
