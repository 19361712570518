.no-data-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 80px;
}

.no-data-container p {
    margin-top: 10px;
    font-size: 16px;
    color: #6c757d;
}