.info-popup {
    position: relative;
    display: inline-block;
    margin-left: 4px;
    cursor: pointer;

    .popup {
        position: absolute;
        top: 0%;
        right: calc(100% + 10px);
        transform: translateY(-50%);
        background-color: #fff;
        border: 1px solid #ccc;
        border-radius: 5px;
        padding: 10px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        white-space: break-spaces;

        &.right {
            right: auto;
            left: calc(100% + 8px);
        }

        .popup-content {
            max-width: 184px;
            min-width: 80px;
        }
    }
}