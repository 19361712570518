.avatar-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.basic-info {
  margin-top: 20px;
}

.basic-info h3 {
  margin-bottom: 5px;
  text-align: center;
}

.basic-info p {
  margin: 0;
}

.edit-btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 16px;
}
