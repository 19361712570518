@import url(//fonts.googleapis.com/css?family=Lato:300:400);
.waves-container {
  position: absolute;
  width: 100%;
  // margin-bottom: 30%;
  // height: 50%;

  h1 {
    font-family: "Lato", sans-serif;
    font-weight: 300;
    letter-spacing: 2px;
    font-size: 48px;
  }

  p {
    font-family: "Lato", sans-serif;
    letter-spacing: 1px;
    font-size: 14px;
    color: #333333;
  }

  .header {
    position: relative;
    text-align: center;
    background: linear-gradient(60deg, rgba(84, 58, 183, 1) 0%, rgba(0, 172, 193, 1) 100%);
    color: white;

    .logo {
      width: 50px;
      fill: white;
      padding-right: 15px;
      display: inline-block;
      vertical-align: middle;
    }

    .inner-header {
      height: 65vh;
      width: 100%;
      margin: 0;
      padding: 0;
    }

    .flex {
      /* Flexbox for containers */
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    .waves {
      margin-top: 30%;
      height: 120px;
      width: 100%;
    }

    .content {
      position: relative;
      height: 20vh;
      text-align: center;
      background-color: white;
    }

    /* Animation */

    .parallax > use {
      animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
    }

    .parallax > use:nth-child(1) {
      animation-delay: -2s;
      animation-duration: 7s;
    }

    .parallax > use:nth-child(2) {
      animation-delay: -3s;
      animation-duration: 10s;
    }

    .parallax > use:nth-child(3) {
      animation-delay: -4s;
      animation-duration: 13s;
    }

    .parallax > use:nth-child(4) {
      animation-delay: -5s;
      animation-duration: 20s;
    }

    @keyframes move-forever {
      0% {
        transform: translate3d(-90px, 0, 0);
      }
      100% {
        transform: translate3d(85px, 0, 0);
      }
    }

    /* Shrinking for mobile */
    @media (max-width: 768px) {
      .waves {
        height: 40px;
        min-height: 40px;
      }

      .content {
        height: 30vh;
      }

      h1 {
        font-size: 24px;
      }
    }
  }
}
