.avatar-container {
  position: relative;
}
.avatar {
  border-radius: 50%;
  overflow: hidden;
  width: 40px;
  height: 40px;
}

.avatar-container > .avatar {
  width: 100px;
  height: 100px;
}
