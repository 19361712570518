// body:has(.modal-rt-sticky) {

// }
.modal-rt-sticky {
  right: 0;
  margin-right: 0;
  margin-top: 0;
  max-width: 420px !important;
  background: #fff;
  height: 100vh;
  overflow: auto;
  .modal-content {
    max-height: 100vh;
    height: 100%;
    & > .card {
      flex: 1;
    }
  }
}
