@import '@fortawesome/fontawesome-free/css/all.min.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.form-date-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.custom-date-picker {
  display: flex;
  align-items: center;
  position: relative;
  .calendar-icon {
    position: absolute;
    right: 16px;
  }
  .is-invalid {
    .form-control {
      border-color: #dc3545;
      padding-right: calc(1.5em + 0.75rem) !important;
      background-repeat: no-repeat;
      background-position: right calc(0.375em + 0.1875rem) center;
      background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }
  }
  .form-control {
    flex: 1;
  }
}

/* Toast notification containter */
.react-toast-notifications__container {
  z-index: 9999 !important;
}
